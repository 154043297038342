import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'
import { Link } from "gatsby"

const ArticlePage = ({location}) =>
  (
    <Layout>

      <SEO title="Selling your car privately during lockdowns"
        description="How Summon can help sell your prestige car during lockdown"
        location={location}
      />

      <PageHeader
        title="Selling your car privately during lockdowns"
        text="Many Australian states have experienced or are currently in lockdown due to COVID-19, how can you sell your car privately during this time? "
      />

      <SectionPageMargin>

          <Heading2>How Summon can help sell your prestige car during lockdown</Heading2>

          <Paragraph1>
            Used Cars in Australia have gone up in value in the past 12 months, but it’s harder than ever to sell your car privately if you are in lockdown.
          </Paragraph1>

          <Paragraph1>
            With many confusing rules to contend with, plus the need to stay healthy and safe, and do the right thing - selling a car during lockdown can be a minefield.
          </Paragraph1>

          <Paragraph1>
            Selling to a Dealer or wholesaler is an option, but means you get a lot less than selling privately (15% less on average).
          </Paragraph1>
          
          <Paragraph1>
          If you want to sell your prestige car for the full retail price, Summon could help (if you are within our service areas). Our service abides by all local COVID-19 legislation, and also guarantees you a fantastic (retail) value for your car. We also do all the work for you!
          </Paragraph1>

          <Paragraph1>
            <strong>How it works</strong><br />
            For prestige cars over $50,000 in value, Summon manage the entire listing, enquiries and sale process on behalf of the customer.
          </Paragraph1>

          <Paragraph1>
            Test-drives of a car are not possible during lockdown, and so we provide the potential buyer with everything they need to make an informed decision and “buy the car online”, including;
          </Paragraph1>
          
          <ul>
            <li>Professional photography (high quality photos of the exterior, interior and engine)</li>
            <li>Redbook Remote Inspection (video call) - an independent quality check for the car</li>
            <li>Live video (Facetime) virtual inspection of the car with the buyer and seller</li>
            <li>The buyer can request an independent Pre-Purchase Inspection (a full mechanical check) prior to purchase</li>
          </ul>
    
          <Paragraph1>
            We complete the transaction online or via phone & email. From finance applications to trade-in appraisals, with no need to visit a dealership or office.
          </Paragraph1>

          <Paragraph1>
            Once the purchase is agreed, Summon will arrange either contactless home-delivery or click-and-collect.
          </Paragraph1>

          <Paragraph1>
            <strong>Request a free valuation now</strong><br />
            If you have a prestige car that you are thinking of selling, request a <Link to="/enquiry">free valuation</Link> today. Let us know your postcode, and we will advise if / how our service will work for your location.
          </Paragraph1>

      </SectionPageMargin>

     <ReadyToGetStarted />

    </Layout>

  )

export default ArticlePage
